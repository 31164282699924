import React from 'react';
import MainPage from "./Components/MainPage";
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {FirebaseProvider} from "./Contexts/FirebaseContext";


import { messages } from "./locales/en/messages";
i18n.load("en", messages);
i18n.activate("en");

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});



function App(props) {
    return (
        <I18nProvider i18n={i18n}>
            <ThemeProvider theme={darkTheme}>
                <FirebaseProvider>
                    <Router>
                        <MainPage window={props.window} />
                    </Router>
                </FirebaseProvider>
            </ThemeProvider>
        </I18nProvider>
    );
}

App.propTypes = {
    window: PropTypes.func,
};

export default App;
