import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import { useLingui } from "@lingui/react";
import ReactCountryFlag from "react-country-flag";
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom'; // Добавьте этот импорт
import LanguageIcon from "@mui/icons-material/Language";
import { useLocation } from 'react-router-dom'

function AppBarComponent({ drawerWidth, mobileOpen, handleDrawerToggle, languages, lang }) {
    const { i18n } = useLingui();
    const ChoLangButtonRef = useRef(null); // Добавьте эту строку

    const [anchorEl, setAnchorEl] = useState(null);
    const [openChoLangMenu, setOpenChoLangMenu] = useState(false); // Добавьте эту строку

    const open = Boolean(anchorEl);
    const location = useLocation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenChoLangMenu(false); // Добавьте эту строку
    };

    const getCurrentPathForLang = (targetLang) => {
        const currentPath = location.pathname

        if (currentPath.startsWith(`/${lang}`)) {
            if (targetLang === languages[0]['code']) {
                return currentPath.replace(`/${lang}`, '')
            } else {
                return currentPath.replace(`/${lang}`, `/${targetLang}`)
            }
        }

        if (lang === languages[0]['code'] && !currentPath.startsWith(`/${lang}`) && targetLang !== languages[0]['code']) {
            return `/${targetLang}${currentPath}`
        }

        return currentPath
    }

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    Responsive drawer
                </Typography>

                <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    sx={{ ml: 'auto', textTransform: 'none' }}
                    endIcon={<Card sx={{ pr: 1, pl: 1, fontSize: '14px !important' }}>⌘ K</Card>}
                >
                    Search
                </Button>

                <Button
                    ref={ChoLangButtonRef}
                    startIcon={<LanguageIcon />}
                    id="positioned-cholang-button"
                    aria-controls={'positioned-cholang-menu'}
                    aria-haspopup="true"
                    aria-expanded={openChoLangMenu ? 'true' : undefined}
                    size="sm"
                    onClick={() => {
                        setOpenChoLangMenu(!openChoLangMenu);
                    }}
                    sx={{ ml: 2 }}

                >
                    {lang.toUpperCase()}
                </Button>

                <Menu
                    id="positioned-cholang-menu"
                    anchorEl={ChoLangButtonRef.current}
                    open={openChoLangMenu}
                    onClose={handleClose}
                    aria-labelledby="positioned-cholang-button"
                    placement="bottom-end"
                    size="sm"
                    classes={{ paper: "langMenu" }}
                    sx={{
                        mt: -3,
                    }}
                >
                    {languages.filter(targetLang => targetLang.code !== lang).map((targetLang, index) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={handleClose}
                                sx={{
                                    '&:hover': {
                                        color: '#ffffff', // Цвет текста при наведении
                                    },
                                }}
                            >
                                <NavLink
                                    to={getCurrentPathForLang(targetLang.code)}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit'
                                    }}
                                    reloadDocument
                                    onClick={handleClose}
                                >
                                    <ReactCountryFlag countryCode={targetLang.flag} />{' '}{targetLang.lang}
                                </NavLink>
                            </MenuItem>
                        )
                    })}
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

AppBarComponent.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    mobileOpen: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired, // Добавьте эту строку
    lang: PropTypes.string.isRequired, // Добавьте эту строку
};

export default AppBarComponent;
