import React from 'react';
import { Container, Typography, Button } from '@mui/material';


function NotFoundPage() {
    return (
        <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
            <Typography variant="h1" gutterBottom>
                404
            </Typography>
            <Typography variant="h4" gutterBottom>
                Страница не найдена
            </Typography>
            <Typography variant="body1" gutterBottom>
                К сожалению, страница, которую вы ищете, не существует или была перемещена.
            </Typography>

        </Container>
    );
}

export default NotFoundPage;
