import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD0Sf3DiGtcSO_6nWH80aqdrg1NbSmXaAM",
    authDomain: "documator-test.firebaseapp.com",
    projectId: "documator-test",
    storageBucket: "documator-test.appspot.com",
    messagingSenderId: "393554694647",
    appId: "1:393554694647:web:6363524f3e177d6f315ef2",
    measurementId: "G-31F0KHWXRY"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
