import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {Button, Container, Divider, Grid, Typography, Box } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { useLingui } from "@lingui/react";
import {useFirebase} from "../Contexts/FirebaseContext";
import LoadingPage from "../Components/Loaders/LoadingPage";
import contentMap from "../Components/Structure/ContentMap";


function PageContent({routeItem,languages,lang}) {
    const location = useLocation();
    const { i18n } = useLingui();
    const { getContent } = useFirebase();
    const [loading, setLoading] = useState(true);
    const [titlePage, setTitlePage] = useState("");
    const [descriptionPage, setDescriptionPage] = useState("");
    const [pageContent, setPageContent] = useState([]);

    const getLinkPath = (languages,lang, route) => {
        // Если выбранный язык является базовым, возвращаем путь без префикса языка

        if (lang === languages[0]['code']) {
            return route
        }

        // В противном случае добавляем префикс языка к пути
        return `/${lang}${route}`
    }

    async function convertContent(content) {

        let newPageContent = [];
        if (content && content.doc && content.doc.content && Array.isArray(content.doc.content)) {
            for (const contentItem of content.doc.content) {

                const componentCreator = contentMap[contentItem.type];
                if (componentCreator) {
                    newPageContent.push(componentCreator(contentItem, content.content[contentItem.content], newPageContent.length));
                }
            }
        }
        setPageContent(newPageContent);
        setLoading(false);
    }

    useEffect(() => {
        setTitlePage("");
        setDescriptionPage("");
        setLoading(true); // Устанавливаем состояние загрузки в true перед началом загрузки данных

        const fetchData = async () => {
            if (routeItem && routeItem.docID) {
                const result = await getContent(routeItem.docID, i18n.locale);
                await convertContent(result);
                if (result){
                    setTitlePage(result.content.titlePage)
                    setDescriptionPage(result.content.descriptionPage)
                }
            }
             // Устанавливаем состояние загрузки в false после завершения загрузки данных
        };

        fetchData();
    }, [routeItem, i18n.locale, getContent]);




    return (
        <Container>
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 1)',
                    zIndex: 1000,
                }}>
                    {/* Здесь можно добавить индикатор загрузки, если нужно */}
                </div>
            )}
            <Typography variant="h3" gutterBottom>
                {titlePage}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {descriptionPage}
            </Typography>
            {pageContent}




            <Divider/>
            <Grid container spacing={2} sx={{mt:1,mb:1}}>
                {routeItem.subPage && routeItem.subPage.map((subPageItem, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                        <Link to={getLinkPath(languages,lang,subPageItem.path)}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    minHeight: 70,
                                }}
                            >
                                <Box display="flex" width="100%">
                                    <Typography variant="h6" align="right">{subPageItem.name}</Typography>
                                </Box>
                            </Button>
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Divider/>
            <Grid container spacing={2} sx={{mt:1,mb:1}}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {routeItem.previous && (
                        <Link to={getLinkPath(languages,lang,routeItem.previous.path)}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                }}
                            >
                                <Box display="flex" width="100%">
                                    <Box width="10%" display="flex" alignItems="center" justifyContent="center">
                                        <ArrowBackIosIcon />
                                    </Box>
                                    <Box width="90%" display="flex" flexDirection="column" justifyContent="center">
                                        <Typography variant="subtitle2" align="right">Previous</Typography>
                                        <Typography variant="h6" align="right">{routeItem.previous.name}</Typography>
                                    </Box>
                                </Box>
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {routeItem.next && (
                        <Link to={getLinkPath(languages,lang,routeItem.next.path)}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                }}
                            >
                                <Box display="flex" width="100%">
                                    <Box width="90%" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                                        <Typography variant="subtitle2" align="left">Next</Typography>
                                        <Typography variant="h6" align="left">{routeItem.next.name}</Typography>
                                    </Box>
                                    <Box width="10%" display="flex" alignItems="center" justifyContent="center">
                                        <ArrowBackIosIcon sx={{ transform: 'rotate(180deg)' }} />
                                    </Box>
                                </Box>
                            </Button>
                        </Link>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

export default PageContent;
