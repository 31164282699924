import {useState,useEffect} from "react";
import DOMPurify from "dompurify";
import { CodeBlock, dracula,a11yLight,a11yDark,codepen,nord } from 'react-code-blocks';

import { Typography, Link, Box,Card,Paper,ImageList,ImageListItem,ImageListItemBar  } from "@mui/material";
import Dialog from '@mui/material/Dialog';

import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const hintColorMap = {
    error: 'error.dark',
    warning: 'warning.dark',
    info: 'info.dark',
    success: 'success.dark',
};
const hintIconMap = {
    error: <ErrorOutlineIcon />,
    warning: <WarningAmberIcon />,
    info: <InfoOutlinedIcon />,
    success: <CheckCircleOutlineIcon />,
};


function ImageListDialog({ contentItem, content, dialogKey }) {
    const [open, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageSize, setImageSize] = useState(164);
    const [loadingImage, setLoadingImage] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setImageSize(window.innerWidth / contentItem.cols);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [contentItem.cols]);



    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
        setLoadingImage(false)
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
        setLoadingImage(false)
    };

    const handleImageLoad = () => {
        setLoadingImage(false);
    };

    const handleImageOpen = (index) => {
        setLoadingImage(true);
        setCurrentIndex(index);
        setOpen(true);
    };

    return (
        <Box key={dialogKey} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ImageList sx={{ width: "100%" }} cols={contentItem.cols} rowHeight={"auto"}>
                {content.map((item, index) => (
                    <ImageListItem key={`${index}-${dialogKey}`} onClick={() => handleImageOpen(index)} sx={{cursor:"pointer"}}>
                        <img
                            srcSet={`${item.img}?w=${Math.floor(imageSize)}&h=${Math.floor(imageSize)}&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=${Math.floor(imageSize)}&h=${Math.floor(imageSize)}&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <IconButton sx={{ position: 'absolute', left: 0 }} onClick={handlePrev}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    {loadingImage && (
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    <img
                        src={content[currentIndex].img}
                        alt={content[currentIndex].title}
                        style={{ width: '100%', objectFit: 'contain', display: loadingImage ? 'none' : 'block' }}
                        onLoad={handleImageLoad}
                    />
                    <ImageListItemBar
                        title={content[currentIndex].title}
                        subtitle={content[currentIndex].subtitle}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about`}
                            >
                                <InfoOutlinedIcon/>
                            </IconButton>
                        }
                    />
                    <IconButton sx={{ position: 'absolute', right: 0 }} onClick={handleNext}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </Dialog>
        </Box>);
}

const ContentMap = {
    "h1": (contentItem, content, key) => (
        <Typography variant="h4" gutterBottom key={key}>
            {content}
        </Typography>
    ),
    "h2": (contentItem, content, key) => (
        <Typography variant="h5" gutterBottom key={key}>
            {content}
        </Typography>
    ),
    "h3": (contentItem, content, key) => (
        <Typography variant="h6" gutterBottom key={key}>
            {content}
        </Typography>
    ),
    "text": (contentItem, content, key) => {
        const cleanContent = DOMPurify.sanitize(content, { ALLOWED_TAGS: ['b', 'i', 's', 'br'] }); // Санитизация HTML с разрешенными тегами
        return (
            <Typography gutterBottom key={key} dangerouslySetInnerHTML={{ __html: cleanContent }} />
        );
    },
    "listuo": (contentItem, content, key) => {
        const cleanContent = DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ["li"], // Разрешить только теги <li>
        });

        return (
            <Box key={key}>
                <ul dangerouslySetInnerHTML={{ __html: cleanContent }} />
            </Box>
        );
    },
    "listo": (contentItem, content, key) => {
        const cleanContent = DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ["li"], // Разрешить только теги <li>
        });

        return (
            <Box key={key}>
                <ol dangerouslySetInnerHTML={{__html: cleanContent}}/>
            </Box>
        );
    },
    "code": (contentItem, content, key) => {
        return (
            <Box key={key}>
                <CodeBlock
                    text={content}
                    language={contentItem.language || 'javascript'}
                    showLineNumbers={true}
                    theme={nord}
                    //wrapLongLines={true}
                />
            </Box>
        );
    },
    "image": (contentItem, content, key) => {
        const widthPercentage = content.length > 1 ? 99 / content.length : 100;

        return (
            <Box sx={{ mt: 1,mb: 1, display: 'flex', flexWrap: 'wrap', gap: '10px' }} key={key}>
                {content.map((item, index) => (
                    <img
                        key={index}
                        src={item.url}
                        alt={item.title}
                        style={{
                            width: `${widthPercentage}%`,
                            borderRadius: '3px', // или '1em' или другое значение по вашему усмотрению
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)' // добавляет небольшую тень для эффекта "поднятия"
                        }}
                    />
                ))}
            </Box>
        );
    },
    "imagelist": (contentItem, content, key) => {
        return (
            <ImageListDialog contentItem={contentItem} content={content} dialogKey={key}/>
        );
    },
    "hint": (contentItem, content, key) => {
        const hintType = contentItem.typehint;
        const hintColor = hintColorMap[hintType] || 'error.dark'; // используем 'error.dark' как цвет по умолчанию
        const hintIcon = hintIconMap[hintType] || <ErrorOutlineIcon />; // используем <ErrorOutlineIcon /> как иконку по умолчанию

        return (
            <Box sx={{ borderRadius: '4px', overflow: 'hidden', minHeight: '70px', display: 'flex', mb:1, }} key={key}>
                <Box sx={{ borderLeft: '2px solid', borderColor: hintColor, height: '100%', minHeight: '70px', display: 'flex' }}>
                    <Box sx={{ width: 'auto', display: 'flex', alignItems: 'center', m: 1, color: hintColor }}>
                        {hintIcon}
                    </Box>
                    <Box sx={{ flexGrow: 1, ml: 1, mr: 1 }}>
                        {content}
                    </Box>
                </Box>
            </Box>
        );
    },
    "quote": (contentItem, content, key) => {
        return (
            <Box sx={{ borderRadius: '4px', overflow: 'hidden', minHeight: '70px', display: 'flex', mb:1, }} key={key}>
                <Box sx={{ borderLeft: '2px solid', borderColor: "grey.300", height: '100%', minHeight: '70px', display: 'flex' }}>
                    <Box sx={{ flexGrow: 1, ml: 1, mr: 1 }}>
                        {content}
                    </Box>
                </Box>
            </Box>
        );
    },

};

export default ContentMap;
