const languages = [
    {
        lang: "English",
        code: "en",
        flag: "gb",
        desc: "English"
    },
    {
        lang: "Español",
        code: "es",
        flag: "es",
        desc: "Spanish"
    },
    {
        lang: "Français",
        code: "fr",
        flag: "fr",
        desc: "French"
    },
    {
        lang: "Deutsch",
        code: "de",
        flag: "de",
        desc: "German"
    },
    {
        lang: "Українська",
        code: "uk",
        flag: "ua",
        desc: "Ukrainian"
    },
    {
        lang: "中文",
        code: "zh-CN",
        flag: "cn",
        desc: "Chinese Simplified"
    },
    {
        lang: "हिन्दी",
        code: "hi",
        flag: "in",
        desc: "Hindi"
    },
    {
        lang: "العربية",
        code: "ar",
        flag: "sa",
        desc: "Arabic"
    },
    {
        lang: "Русский",
        code: "ru",
        flag: "ru",
        desc: "Russian"
    },
    {
        lang: "Português",
        code: "pt",
        flag: "pt",
        desc: "Portuguese"
    },
    {
        lang: "日本語",
        code: "ja",
        flag: "jp",
        desc: "Japanese"
    },
    {
        lang: "Italiano",
        code: "it",
        flag: "it",
        desc: "Italian"
    },
    {
        lang: "Türkçe",
        code: "tr",
        flag: "tr",
        desc: "Turkish"
    },
    {
        lang: "한국어",
        code: "ko",
        flag: "kr",
        desc: "Korean"
    },
    {
        lang: "Nederlands",
        code: "nl",
        flag: "nl",
        desc: "Dutch"
    },
    {
        lang: "ไทย",
        code: "th",
        flag: "th",
        desc: "Thai"
    }
];

export default languages;