import React, { useState } from 'react';
import { Drawer, Box, Toolbar, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse,ListSubheader,Card,CardContent , Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const iconMap = {
    InboxIcon: <InboxIcon />,
    MailIcon: <MailIcon />,
    AccessibilityIcon: <AccessibilityIcon />,
};




const getLinkPath = (languages,lang, route) => {
    // Если выбранный язык является базовым, возвращаем путь без префикса языка
    if (lang === languages[0]['code']) {
        return route
    }

    // В противном случае добавляем префикс языка к пути
    return `/${lang}${route}`
}




function RecursiveList({ items, handleClick,languages,lang }) {
    const location = useLocation();
    return (
        <List component="div" disablePadding>
            {items.map((item, index) => (
                <React.Fragment key={item.name}>
                    <ListItemButton sx={{ pl: 4 }} selected={location.pathname === item.path}>
                        <ListItemIcon>
                            {iconMap[item.icon]}
                        </ListItemIcon>
                        <Link to={getLinkPath(languages,lang, item.path)} style={{ textDecoration: 'none', color: 'inherit', flex: 1 }}>
                            <ListItemText primary={item.name} />
                        </Link>
                        {item.subPage && (item.subPage.length > 0 ? (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation(); // останавливаем всплытие события
                                    handleClick(item.name);
                                }}
                            >
                                {item.open ? <ExpandLess /> : <ExpandMore />}
                            </span>
                        ) : null)}
                    </ListItemButton>
                    {item.subPage && item.subPage.length > 0 && (
                        <Collapse in={item.open} timeout="auto" unmountOnExit>
                            <RecursiveList items={item.subPage} handleClick={handleClick} languages={languages} lang ={lang} />
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
        </List>
    );
}


RecursiveList.propTypes = {
    items: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
};

function groupItemsBySubheader(items) {
    return items.reduce((acc, currentItem) => {
        (acc[currentItem.subheader || "noSubheader"] = acc[currentItem.subheader || "noSubheader"] || []).push(currentItem);
        return acc;
    }, {});
}




function DrawerComponent({ drawerWidth, mobileOpen, handleDrawerToggle, container, primaryMenuItems,languages, lang }) {
    const [menuItems, setMenuItems] = useState(primaryMenuItems);
    const location = useLocation();

    function handleClick(name) {
        function toggleOpen(items) {
            return items.map(item => {
                if (item.name === name) {
                    return { ...item, open: !item.open };
                }
                if (item.subPage) {
                    return { ...item, subPage: toggleOpen(item.subPage) };
                }
                return item;
            });
        }

        setMenuItems(prevItems => toggleOpen(prevItems));

    }




    const drawer = (
        <div>
            <Toolbar>
                LOGO
            </Toolbar>
            <Divider />

            {
                Object.entries(groupItemsBySubheader(menuItems)).map(([subheader, itemsInGroup]) => (
                    <List
                        key={subheader}
                        component="div"
                        disablePadding
                        subheader={
                            subheader !== "noSubheader" ? (
                                <ListSubheader component="div" id={`${subheader}-subheader`}>
                                    {subheader}
                                </ListSubheader>
                            ) : null
                        }
                        sx={{ mb: subheader !== "noSubheader" ? 2 : 0 }}
                    >
                        {itemsInGroup.map((item, index) => (
                            <React.Fragment key={item.name}>
                                <ListItemButton selected={location.pathname === item.path}>
                                    <ListItemIcon>
                                        {iconMap[item.icon]}
                                    </ListItemIcon>
                                    <Link
                                        to={getLinkPath(languages,lang, item.path)}
                                        style={{ textDecoration: 'none', color: 'inherit', flex: 1 }}
                                    >
                                        <ListItemText primary={item.name} />
                                    </Link>
                                    {item.subPage && (item.subPage.length > 0 ? (
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation(); // останавливаем всплытие события
                                                handleClick(item.name);
                                            }}
                                        >
                    {item.open ? <ExpandLess /> : <ExpandMore />}
                </span>
                                    ) : null)}
                                </ListItemButton>
                                {item.subPage && item.subPage.length > 0 && (
                                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                                        <RecursiveList items={item.subPage} handleClick={handleClick} languages={languages} lang ={lang} />
                                    </Collapse>
                                )}
                            </React.Fragment>
                        ))}



                    </List>
                ))
            }

            <Divider />
        </div>
    );

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                <Box sx={{ flexGrow: 1 }}>
                    {drawer}
                </Box>
                <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary" gutterBottom>
                            Powered By Docum.space
                        </Typography>
                    </CardContent>
                </Card>
            </Drawer>
        </Box>
    );
}

DrawerComponent.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    mobileOpen: PropTypes.bool.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
    container: PropTypes.func,
    primaryMenuItems: PropTypes.array.isRequired, // новый проп
};

export default DrawerComponent;