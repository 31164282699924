import React, {createContext, useContext, useCallback, useEffect} from "react";
import { db, auth } from "./firebase";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const FirebaseContext = createContext();

export const useFirebase = () => {
    return useContext(FirebaseContext);
};

export const FirebaseProvider = ({ children }) => {
    const sitespace = window.location.hostname;


    function updatePath(items, parentPath = "") {
        return items.map(item => {
            const newPath = parentPath ? `${parentPath}/${item.path}` : `/${item.path}`;
            let updatedItem = { ...item, path: newPath };
            if (item.subPage) {
                updatedItem = { ...updatedItem, subPage: updatePath(item.subPage, newPath) };
            }
            return updatedItem;
        });
    }

    const getMenuItems = useCallback(async () => {
        try {

            const docRef = doc(db, `spaces/${sitespace}/content/menuItems`);

            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                const updatedData = { ...data, MenuItems: updatePath(data.MenuItems) };
                return updatedData;
            } else {
                console.log("No such space!");
                return false
            }
        } catch (error) {
            console.error("Error getting document:", error);
            return false
        }
    }, [db]);


    const getSpaceLangs = useCallback(async () => {
        try {
            const docRef = doc(db, `spaces/${sitespace}`);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists() && docSnap.data().langs) {
                return docSnap.data().langs;
            } else {
                console.log("No such space or langs field not found!");
                return false;
            }
        } catch (error) {
            console.error("Error getting document:", error);
            return false;
        }
    }, [db, sitespace]);

    const getContent = useCallback(async (DocID, lang) => {

        try {
            const docRef = doc(db, `spaces/${sitespace}/pages/${DocID}`);
            const contentRef = doc(db, `spaces/${sitespace}/pages/${DocID}/content/${lang}`);
            const docSnap = await getDoc(docRef);
            const contentSnap = await getDoc(contentRef);

            if (docSnap.exists() && contentSnap.exists()) {
                return { doc: docSnap.data(), content: contentSnap.data() };
            } else {
                console.log("No such document or content found!");
                return false;
            }
        } catch (error) {
            console.error("Error getting document or content:", error);
            return false;
        }


    }, [db, sitespace]);


    return (
        <FirebaseContext.Provider value={{ db, auth, getMenuItems,getSpaceLangs,getContent }}>
            {children}
        </FirebaseContext.Provider>
    );
};
