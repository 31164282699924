import React, {useEffect} from 'react';
import {Routes, Route } from 'react-router-dom';
import AppBarComponent from "../AppBarComponent";
import DrawerComponent from "../DrawerComponent";
import PageContent from "../../Pages/PageContent";
import { Box, CssBaseline, Toolbar } from '@mui/material';
import {useFirebase} from "../../Contexts/FirebaseContext";
import NotFoundPage from "../../Pages/404";
import { useLocation } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { Trans } from "@lingui/macro";


import languages from "../../languages";



const drawerWidth = 300;

function MainPage({ window }) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [menuItems, setMenuItems] = React.useState([]);
    const [routeItems, setRouteItems] = React.useState([]);
    const [dataFetchFailed, setDataFetchFailed] = React.useState(false);
    const [spaceLangs, setSpaceLang] = React.useState([]);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [lang, setLang] = React.useState();
    const location = useLocation();


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const container = window !== undefined ? () => window().document.body : undefined;
    const { getMenuItems,getSpaceLangs } = useFirebase();



    function sortItemsByOrder(items, lang) {
        let result = [];

        items.sort((a, b) => a.order - b.order).forEach((item, index) => {
            if (item.name && typeof item.name === 'object') {
                item.name = item.name[lang] || item.name['en']; // выбираем нужный язык или английский по умолчанию
            }
            if (item.subheader && typeof item.subheader === 'object') {
                item.subheader = item.subheader[lang] || item.subheader['en']; // выбираем нужный язык или английский по умолчанию
            }
            if (item.subPage) {
                item.subPage = sortItemsByOrder(item.subPage, lang); // передаем выбранный язык в рекурсивный вызов
            }
            result.push(item);
        });
        return result;
    }


    function createMenuItemsArray(items, parentItem = null) {
        let result = [];

        items.forEach((item, index, arr) => {
            let previous = index > 0 ? { name: arr[index - 1].name, path: arr[index - 1].path } : null;
            let next = index < arr.length - 1 ? { name: arr[index + 1].name, path: arr[index + 1].path } : null;

            let subPage = item.subPage ? item.subPage.map(subItem => ({ name: subItem.name, path: subItem.path })) : null;

            let newItem = {
                name: item.name,
                path: item.path,
                docID: item.docID,
                icon: item.icon,
                previous: previous,
                next: next,
                subPage: subPage,
                parent: parentItem ? { name: parentItem.name, path: parentItem.path } : null
            };

            result.push(newItem);

            if (item.subPage) {
                result = result.concat(createMenuItemsArray(item.subPage, item));
            }
        });

        return result;
    }

    function addPreviousAndNext(menuItemsArray) {
        return menuItemsArray.map((item, index, arr) => {
            let previous = index > 0 ? { name: arr[index - 1].name, path: arr[index - 1].path } : null;
            let next = index < arr.length - 1 ? { name: arr[index + 1].name, path: arr[index + 1].path } : null;

            return {
                ...item,
                previous: previous,
                next: next,
            };
        });
    }

    useEffect(() => {
        const fetchMenuItems = async () => {

            const spaceLangs = await getSpaceLangs();
            let filteredLanguages;
            if(spaceLangs) {
                filteredLanguages = spaceLangs.map(langCode =>
                    languages.find(lang => lang.code === langCode)
                ).filter(Boolean); // Этот фильтр удаляет undefined элементы, если какой-то код языка не найден
                setSpaceLang(filteredLanguages);

            }

            let detlang = location.pathname.split('/')[1];

            if (!filteredLanguages.find(item => item.code === detlang)) {
                detlang = filteredLanguages[0].code;
            }
            const { messages } = await import(`../../locales/${detlang}/messages.js`);
            i18n.load(detlang, messages);
            i18n.activate(detlang);
            setLang(detlang);


            const fetchedMenuItems = await getMenuItems();
            if (fetchedMenuItems === false) {
                setDataFetchFailed(true);
                return;
            }

            const sortedMenuItems = sortItemsByOrder(fetchedMenuItems.MenuItems, detlang);
            sortedMenuItems[0].path="/";
            setMenuItems(sortedMenuItems);


            let menuItemsArray = createMenuItemsArray(sortedMenuItems);
            menuItemsArray[0].path="/";
            menuItemsArray = addPreviousAndNext(menuItemsArray);
            setRouteItems(menuItemsArray);



            setDataLoaded(true);
        };

        fetchMenuItems();
    }, [getMenuItems,getSpaceLangs]);




    if (dataFetchFailed) {
        return <NotFoundPage />;
    }


    return (
        dataLoaded  ? (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBarComponent drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} languages={spaceLangs} lang={lang}/>
                <DrawerComponent
                    drawerWidth={drawerWidth}
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={handleDrawerToggle}
                    container={container}
                    primaryMenuItems={menuItems}
                    languages={spaceLangs}
                    lang={lang}
                />
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Routes>
                        {routeItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <Route
                                    key={index}
                                    path={item.path}
                                    element={<PageContent routeItem={item} languages={spaceLangs} lang={lang}/>}
                                />
                                {spaceLangs.slice(1).map((language) => {
                                    return (
                                        <Route
                                            key={`${index}${language.code}`}
                                            path={`${language.code}${item.path}`}
                                            element={<PageContent routeItem={item} languages={spaceLangs} lang={lang}/>}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        ))}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>

                </Box>
            </Box>
        ) : (
            <div>Loading...</div> // или любой другой индикатор загрузки
        )
    );
}


export default MainPage;
